import React, { useEffect, useRef, useState } from "react"

import {
  CarOutlined, DeleteOutlined,
  DownloadOutlined,
  MinusCircleOutlined,
  PrinterOutlined,
  ReloadOutlined,
  ShoppingCartOutlined,
  UndoOutlined
} from "@ant-design/icons"
import { Button, Checkbox, Popconfirm, Space, Tooltip,Image } from "antd"

import { stockFBSMarketApi } from "../../../common/api/endpoints/stockFBSMarketApi"
import ColorTag from "../../../common/components/color-tag/ColorTag"
import AutoWidthSelect from "../../../common/components/selects/AutoWidthSelect"
import { useActions } from "../../../common/hooks/useActions"
import { useTypedSelector } from "../../../common/hooks/useTypedSelector"
import { OrderStatus } from "../../../types/api"
import { marketPlacePictureMap } from "../../../utils/data"
import { columnsWithSortingHeader } from "../../../utils/function"
import { showErrorMessage } from "../../../utils/ui"


const LOCAL_STORAGE_KEY = "savedFBSColumns"

export type Column = any
export type Sorting = {
    [x: string]: string
}
const loadColumnsFromLocalStorage = (defaultColumns: Column[]) => {
  const savedColumns = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (savedColumns) {
    const columnsKeys = JSON.parse(savedColumns)?.map((col: { key: string }) => col.key)
    const sortedDefaultColumns = [ ...defaultColumns ].sort((a, b) => {
      return columnsKeys.indexOf(a.key) - columnsKeys.indexOf(b.key)
    })
    return sortedDefaultColumns.filter(el => [ ...columnsKeys,"selection" ].includes(el.key))
  }
  return defaultColumns
}
const sortedColumns = [
  "partner_id",
  "closed_by",
  "quantity",
  "arrival_date",
  "assembly_date",
  "delivery_date",
  "created_at",
  "stock_quantity",
  "name",
  "sku",
  "purchase_price",
  "real_price",
  "barcode",
  "status_info",
  "product_info",
  "marketplace_id",
  "error_msg",
  "order_id",
  "org",
  "price",
  "time_since_order"
]

const useStockFbsColumns = (props: {
  setAddSupplierState: Function,
  handleAction: Function,
  filters:any,
  setChangeCisState: React.Dispatch<React.SetStateAction<{visible: boolean, id: number | null, cis: string[] | null}>>,
}):[
    columns:Column[],
    setColumns:React.Dispatch<React.SetStateAction<any[]>>,
    defaultColumns:Column[],
    sorting:Sorting,
    selectedIds: string[],
    setSelectedIds:React.Dispatch<React.SetStateAction<string[]>>
] => {
  const [ sorting, setSorting ] = useState<{ [x: string]: string }>({
    arrival_date: "DESC",
  })
  const { setAddSupplierState,handleAction } = props
  const {
    stockFBSUpdateSupply,
    stockFBSRemoveSticker,
    stockFBSUpdateSticker,
    setVisibleDownloadQuantityModal,
  } = useActions()
  const [ isDisabledDownloadButton,setDisabledDownloadButton ] = useState(false)
  const [ selectedIds,setSelectedIds ] = useState<string[]>([])
  const debounceRef = useRef<boolean>(false)


  const handleSort = (columnKey: string) => {
    setSorting((prevSorting) => {
      if (prevSorting[columnKey]) {
        return {
          [columnKey]:prevSorting[columnKey] === "ASC" ? "DESC" : "ASC"
        }
      } else {
        return {
          [columnKey]: "ASC",
        }
      }
    })
  }

  const handleDownloadSticker = async (order_id:number,isDownload=true) => {
    try{
      if(debounceRef.current) return
      debounceRef.current = true
      setDisabledDownloadButton(true)
      setTimeout(() => debounceRef.current = false, 2500)
      await stockFBSMarketApi.downloadSticker(order_id,isDownload,setVisibleDownloadQuantityModal)
    }catch(err:any){
      showErrorMessage("Произошла ошибка, повторите попытку позже.")
      console.log(err)
    }finally{
      setDisabledDownloadButton(false)
    }
  }
  const handleDownloadLabel = async (order_id:number,isDownload=true) => {
    try{
      setDisabledDownloadButton(true)
      await stockFBSMarketApi.downloadLabel(order_id,isDownload)
    }catch(err){
      showErrorMessage("Произошла ошибка, повторите попытку позже.")
      console.log(err)
    }finally{
      setDisabledDownloadButton(false)
    }
  }
  const handleUpdateSticker = async (record:{id:number,order_id:number,marketplace_id:number,partner_id:number}) => {
    try{
      const data = {
        id:record?.id,
        order_id:record?.order_id,
        partner_id:record?.partner_id,
        marketplace_id:record?.marketplace_id
      }
      stockFBSUpdateSticker(data)
    }catch(err){
      console.log(err)
    }
  }
  const handleSupplyChange = (id:number,supply_id:number) => {
    stockFBSUpdateSupply({
      id,
      supply_id
    })
  }
  const handleCreateNewSupplier = (record:{id?:number | null,order_id?:number | null,marketplace_id?:number | null,partner_id?:number | null}) => {
    setAddSupplierState({
      visible:true,
      id:record?.id,
      partner_id:record?.partner_id,
      marketplace_id:record?.marketplace_id,
      name:""
    })
  }


  const { itemData } = useTypedSelector((state) => state.stockFBS)
  const handleClickAll = () => {
    if(itemData?.length === selectedIds?.length){
      setSelectedIds([])
    }else{
      const allIds = itemData?.map((el)=>el?.order_id?.toString())
      setSelectedIds(allIds)
    }
  }

  const initialColumns = [
    {
      key: "selection",
      dataKey: "selection",
      width: 40,
      align:"center",
      selectedIds,
      itemData,
      title:<Checkbox onClick={handleClickAll} />,
      cellRenderer: ({ rowData }: any) => {
        return (
          <Checkbox
            checked={selectedIds.includes(rowData.order_id.toString())}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedIds((prev) => [ ...prev, rowData.order_id.toString() ])
              } else {
                setSelectedIds((prev) => prev.filter(id => id !== rowData.order_id.toString()))
              }
            }}
          />
        )
      },
      frozen:true,
    },
    {
      key: "status_info",
      title: "Статус",
      dataKey: "status_info",
      width: 220,
      align: "center",
      cellRenderer: ({ cellData }:any) => {
        const color = `#${cellData?.color}` || "red"
        return (
          <ColorTag color={color}>
            <span style={{ color: "white", fontWeight: "600", padding: "12px", fontSize: "14px" }}>
              {cellData?.name}
            </span>
          </ColorTag>
        )
      }
    },
    {
      key: "error_msg",
      title: "Ошибка",
      dataKey: "error_msg",
      width: 150,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={cellData}>
          {cellData}
        </Tooltip>
      )
    },
    {
      key: "marketplace_id",
      title: "Маркетплейс",
      dataKey: "marketplace_id",
      width: 150,
      align: "center",
      cellRenderer: ({ cellData }:any) => (
        <img alt="Маркетплейс" style={{ borderRadius: "50%", width: "28px" }} src={marketPlacePictureMap[cellData]} />
      )
    },
    {
      key: "order_id",
      title: "Номер заказа",
      dataKey: "order_id",
      width: 150,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={cellData}>
          {cellData}
        </Tooltip>
      )
    },
    {
      key: "price",
      title: "Цена",
      dataKey: "price",
      width: 150,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={`${cellData}`}>
          {cellData}
        </Tooltip>
      )
    },
    {
      key: "product_info",
      title: "Товар",
      dataKey: "product_info",
      width: 470,
      cellRenderer: ({ cellData, rowData }:any) =>(
        <div style={{ display: "flex" }}>
          <div style={{ height: "100%" }}>
            <Image alt="Основное фото" style={{ width: "60px" }} src={cellData?.photo_url || "https://brilliant24.ru/files/cat/template_01.png"} />
          </div>
          <div style={{ display: "flex", marginLeft: "6px", flexDirection: "column", height: "100%" }}>
            {cellData?.is_cis_marked &&
            <div style={{ maxWidth: "26px", maxHeight: "26px"  }}>
              <img alt="Честный знак" style={{ height: "26px" }} src={"https://penzakassa.ru/wp-content/uploads/znak_ch_znak.png"} />
            </div>}
            <span><strong>1</strong> шт</span>
            <span><strong>{cellData?.purchase_price}</strong> руб</span>
            {cellData?.is_cis_marked ?
              <Button  disabled={(rowData?.status_info?.id !== 1 && rowData?.status_info?.id !== 2 && rowData?.status_info?.id !== 3)} style={{ width: "50px", height: "22.8px" }} onClick={()=>props.setChangeCisState({ visible: true, id: rowData?.id, cis: cellData?.cis })}>КИЗЫ</Button>
              : null }
          </div>
          <div style={{ display: "flex", marginLeft: "6px", flexWrap: "wrap", flexDirection: "column" }}>
            <strong>{cellData?.product_name}</strong>
            <span>Цвет: {cellData?.color}</span>
            <span>Размер: {cellData?.product_size}</span>
            <Space style={{ marginTop: "4px" }}>
              {cellData?.marketplace_id && (
                <a href={cellData?.product_url} target="_blank" rel="noopener noreferrer">
                  <img alt="Маркетплейс" style={{ borderRadius: "50%", width: "25px", height: "25px" }} src={marketPlacePictureMap[cellData?.marketplace_id]} />
                </a>
              )}
              {cellData?.vk_url && (
                <a href={cellData?.vk_url} target="_blank" rel="noopener noreferrer">
                  <img alt="VK" style={{ borderRadius: "50%", width: "25px", height: "25px" }} src="https://upload.wikimedia.org/wikipedia/commons/f/f3/VK_Compact_Logo_%282021-present%29.svg" />
                </a>
              )}
            </Space>
          </div>
        </div>
      )
    },
    {
      key: "sticker_info",
      title: "Стикер",
      dataKey: "sticker_info",
      width: 150,
      cellRenderer: ({ cellData, rowData }:any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Space>
            {cellData?.part_a}
            {cellData?.part_b && <span style={{ marginLeft: "4px", padding: "4px", background: "black", color: "white" }}>{cellData?.part_b}</span>}
          </Space>
          <Space>
            <ReloadOutlined onClick={() => handleUpdateSticker(rowData)} />
            <DownloadOutlined disabled={isDisabledDownloadButton} onClick={() => handleDownloadSticker(rowData?.order_id)} />
            <Popconfirm title="Подтвердите удаление" onConfirm={() => stockFBSRemoveSticker(rowData?.order_id)}>
              <DeleteOutlined />
            </Popconfirm>
            <PrinterOutlined  disabled={isDisabledDownloadButton} onClick={() => handleDownloadSticker(rowData?.order_id, false)} />
          </Space>
        </Space>
      )
    },
    {
      key: "label",
      title: "Этикетки",
      dataKey: "label",
      width: 150,
      cellRenderer: ({ cellData, rowData }:any) => (
        <Tooltip placement="topLeft" title={cellData || "Этикетка"}>
          <Space>
            {cellData || "Этикетка"}
            <DownloadOutlined onClick={() => handleDownloadLabel(rowData?.order_id)} />
            <PrinterOutlined onClick={() => handleDownloadLabel(rowData?.order_id, false)} />
          </Space>
        </Tooltip>
      )
    },
    {
      key: "delivery_date",
      title: "Дата доставки",
      dataKey: "delivery_date",
      width: 150,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={cellData}>
          {cellData}
        </Tooltip>
      )
    },
    {
      key: "stock_quantity",
      title: "Остатки",
      dataKey: "stock_quantity",
      width: 150,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={`${cellData}`}>
          {cellData}
        </Tooltip>
      )
    },
    {
      key: "org",
      title: "Организация",
      dataKey: "org",
      width: 200,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={cellData}>
          {cellData}
        </Tooltip>
      )
    },
    {
      key: "purveyor_name",
      title: "Поставщик",
      dataKey: "purveyor_name",
      width: 200,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={cellData}>
          {cellData}
        </Tooltip>
      )
    },
    {
      key: "supplies_info",
      title: "Поставка",
      dataKey: "supplies_info",
      width: 300,
      cellRenderer: ({ cellData, rowData }:any) => (
        <AutoWidthSelect
          disabled={[ 5,6,7,8,9 ].includes(rowData?.status_info?.id)}
          value={cellData?.current_supply?.name}
          style={{
            width:293
          }}
          options={[ ...(cellData?.all_supply || []), { id: -1, name: "Создать новую" } ]}
          placeholder="Выберите поставку"
          onChange={(value) => {
            if (value === -1) {
              handleCreateNewSupplier(rowData)
            } else {
              handleSupplyChange(rowData?.id, value)
            }
          }}
        />
      )
    },
    {
      key: "closed_by",
      title: "Кто закрыл?",
      dataKey: "closed_by",
      width: 150,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={cellData}>
          {cellData}
        </Tooltip>
      )
    },
    {
      key: "assembly_date",
      title: "Дата сборки",
      dataKey: "assembly_date",
      width: 150,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={cellData}>
          {cellData}
        </Tooltip>
      )
    },
    {
      key: "created_at",
      title: "Дата создания",
      dataKey: "created_at",
      width: 150,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={cellData}>
          {cellData}
        </Tooltip>
      )
    },
    {
      key: "arrival_date",
      title: "Дата на МП",
      dataKey: "arrival_date",
      width: 150,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={cellData}>
          {cellData}
        </Tooltip>
      )
    },
    {
      key: "time_since_order",
      title: "Время с момента заказа",
      dataKey: "time_since_order",
      width: 150,
      cellRenderer: ({ cellData }:any) => (
        <Tooltip placement="topLeft" title={cellData?.value}>
          <strong style={{ color: `#${cellData?.color}` }}>{cellData?.value}</strong>
        </Tooltip>
      )
    },
    {
      key: "operation",
      title: "Действия",
      width: 150,
      frozen:"right",
      cellRenderer: ({ rowData }:any) => {
        const { status_info } = rowData
        const buttons = []
        const buttonStyle = {
          backgroundColor: "#00a2e8",
          borderRadius: "50%",
          color: "#fff",
        }

        if (status_info?.id === OrderStatus.New) {
          buttons.push(
            <Tooltip title="На сборке" key="build">
              <Button
                onClick={() => handleAction(OrderStatus.Build,
                  { order_ids: [ rowData?.order_id ],
                    marketplace_id: rowData?.marketplace_id,
                    selectedRowKeys:selectedIds })}
                style={buttonStyle}
                icon={<ShoppingCartOutlined />}
              />
            </Tooltip>
          )
          buttons.push(
            <Tooltip title="Собран" key="collected">
              <Button
                onClick={() => handleAction(OrderStatus.Collected, { order_ids: [ rowData?.order_id ],
                  marketplace_id: rowData?.marketplace_id,
                  selectedRowKeys:selectedIds })}
                style={buttonStyle}
                icon={<CarOutlined />}
              />
            </Tooltip>
          )
          buttons.push(
            <Popconfirm title="Подтвердите отмену?" onConfirm={() => handleAction(OrderStatus.Cancel, { order_ids: [ rowData?.order_id ], marketplace_id: rowData?.marketplace_id,selectedRowKeys:selectedIds })}>
              <Tooltip title="Отменить заказ" key="cancel">
                <Button style={buttonStyle} icon={<MinusCircleOutlined />} />
              </Tooltip>
            </Popconfirm>
          )
        } else if (status_info?.id === OrderStatus.Build) {
          buttons.push(
            <Tooltip title="Вернуть заказ в новые" key="returnToNew">
              <Button
                onClick={() => handleAction(OrderStatus.New, { order_ids: [ rowData?.order_id ], marketplace_id: rowData?.marketplace_id })}
                style={buttonStyle}
                icon={<UndoOutlined />} />
            </Tooltip>
          )
          buttons.push(
            <Tooltip title="Собран" key="collected">
              <Button
                onClick={() => handleAction(OrderStatus.Collected, {
                  order_ids: [ rowData?.order_id ],
                  marketplace_id: rowData?.marketplace_id
                })}
                style={buttonStyle} icon={<CarOutlined />} />
            </Tooltip>
          )
          buttons.push(
            <Popconfirm title="Подтвердите отмену?" onConfirm={() => handleAction(OrderStatus.Cancel, { order_ids: [ rowData?.order_id ], marketplace_id: rowData?.marketplace_id,selectedRowKeys:selectedIds })}>
              <Tooltip title="Отменить заказ" key="cancel">
                <Button style={buttonStyle} icon={<MinusCircleOutlined />} />
              </Tooltip>
            </Popconfirm>
          )
        } else if (status_info?.id === OrderStatus.Collected) {
          buttons.push(
            <Tooltip title="На сборке" key="build">
              <Button
                onClick={() => handleAction(OrderStatus.Build,
                  { order_ids: [ rowData?.order_id ],
                    marketplace_id: rowData?.marketplace_id,
                    selectedRowKeys:selectedIds })}
                style={buttonStyle}
                icon={<ShoppingCartOutlined />}
              />
            </Tooltip>
          )
          buttons.push(
            <Tooltip title="Вернуть заказ в новые" key="returnToNew">
              <Button
                onClick={() => handleAction(OrderStatus.New, { order_ids: [ rowData?.order_id ], marketplace_id: rowData?.marketplace_id })}
                style={buttonStyle}
                icon={<UndoOutlined />} />
            </Tooltip>
          )
        }else if (status_info?.id === OrderStatus.Sended){
          buttons.push(
            <Tooltip title="Вернуть заказ в новые" key="returnToNew">
              <Button
                onClick={() => handleAction(OrderStatus.New, { order_ids: [ rowData?.order_id ], marketplace_id: rowData?.marketplace_id })}
                style={buttonStyle}
                icon={<UndoOutlined />} />
            </Tooltip>
          )
        }

        return <Space>{buttons}</Space>
      }
    }
  ]

  const [ defaultColumns,setDefaultColumns ] = useState(initialColumns)
  const [ columns, setColumns ] = useState(() => columnsWithSortingHeader(loadColumnsFromLocalStorage(defaultColumns),sorting,sortedColumns,handleSort))
  useEffect(() => {
    setColumns(columnsWithSortingHeader(loadColumnsFromLocalStorage(initialColumns),sorting,sortedColumns,handleSort))
    setDefaultColumns(columnsWithSortingHeader(initialColumns,sorting,sortedColumns,handleSort))
  }, [ itemData,selectedIds,sorting ])

  return [ columns, setColumns, defaultColumns,sorting,selectedIds,setSelectedIds ]
}

export default useStockFbsColumns
