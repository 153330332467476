import React, { SetStateAction, useState } from "react"

import { Input, Modal, Radio, Switch } from "antd"

import { AddExportTask } from "../../../redux/slices/export/types"
import { showErrorMessage, showSuccessMessage } from "../../../utils/ui"
import { exportApi } from "../../api/endpoints/exportApi"
type Props = {
  setVisible: React.Dispatch<SetStateAction<boolean>>
  dataToExport: AddExportTask
  onExportTypeChange: (exportType: number) => void;
  selectedIds?: (string | number)[]
}
const ExportTable = ({ setVisible, dataToExport, onExportTypeChange, selectedIds }: Props) => {
  const [ filename, setFilename ] = useState("")
  const [ exportType, setExportType ] = useState(1)
  const handleCancel = () => {
    setVisible(false)
  }
  const handleAddTask = async () => {
    if (filename.length === 0) {
      showErrorMessage("Введите название файла.")
    } else {
      try {
        const response = await exportApi.addExportTask({
          ...dataToExport,
          name: filename,
          export_type: exportType,
          ids: selectedIds?.length? selectedIds : undefined
        })
        if (response.status === 200) {
          showSuccessMessage("Задача на экспорт успешно добавлена.")
          setVisible(false)
        }
      } catch (err) {
        showErrorMessage("Произошла ошибка во время добавления задачи на экспорт.")
      }
    }
  }

  const handleExportTypeChange = (e: any) => {
    const selectedType = e.target.value
    setExportType(selectedType)
    onExportTypeChange(selectedType)
  }
  return (
    <Modal open={true} onOk={handleAddTask} onCancel={handleCancel} title={"Экспорт таблицы"} okText={"Сохранить"}>
      <Input value={filename} onChange={(e) => setFilename(e.target.value)} maxLength={255} placeholder={"Введите название файла"} />
      <div style={{ marginTop: 16 }}>
        <Radio.Group style={{ display: "flex", flexDirection: "column" }} onChange={handleExportTypeChange} value={exportType}>
          <Radio value={1}>Обычная выгрузка</Radio>
          <Radio value={2}>Для инвентаризации</Radio>
          <Radio value={3}>Для смены артикулов</Radio>
        </Radio.Group>
      </div>
    </Modal>
  )
}
export default ExportTable
