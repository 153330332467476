import React, { FC, useEffect, useState } from "react"

import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined, HomeOutlined,
  LoadingOutlined
} from "@ant-design/icons"
import { Button, Collapse, Input, Modal, Space, Table, Tabs, Tooltip } from "antd"
import { ColumnsType } from "antd/es/table"

import AddModal from "./components/AddModal"
import EditModal from "./components/EditModal"
import FilterTable from "./components/Filter"
import WarehouseEditModal from "./components/WarehouseEditModal"
import WarehouseModal from "./components/WarehouseModal"
import ColorTag from "../../common/components/color-tag/ColorTag"
import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import { allTagColors, errorTagColor, successTagColor } from "../../common/constants/colors"
import { defaultItemsFilters } from "../../common/constants/filters"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { marketPlacePictureMap } from "../../utils/data"
import { showSuccessMessage } from "../../utils/ui"
const Stock: FC = () => {
  const [ filters, setFilters ] = useState(defaultItemsFilters)
  const [ isAddOpen, setIsAddOpen ] = useState(false)
  const [ isWarehouseOpen, setIswarehouseOpen ] = useState(false)
  const [ isEditOpen, setIsEditOpen ] = useState(false)
  const [ isEditGroupOpen, setIsEditGroupOpen ] = useState(false)
  const [ currentTab, setCurrentTab ] = useState("1")
  const [ editData, setEditData ] = useState({
    id: "",
    name: "",
    api_key_standard: "",
    api_key_stats: ""
  })
  const [ editGroupData, setEditGroupData ] = useState({
    id: "",
    name: "",
    selected_warehouses: [],
  })
  const [ sorting, setSorting ] = useState<{ [x: string]: string }>({
    arrival_date: "DESC",
  })

  const { data, isLoading, groupItems } = useTypedSelector((state) => state.stock)
  const { stockGetList, stockDelete,stockDeleteGroup, stockGetFilterList, stockGetGroupItems } = useActions()
  const [ pageSize, setPageSize ] = useState(10)


  const [ tempData, setTempData ] = useState(data)

  useEffect(() => {
    setTempData(data)
  }, [ data ])

  useEffect(() => {
    stockGetList(filters)
    stockGetFilterList()
    stockGetGroupItems()
  }, [])


  const partnersColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      width: 8,

      render: (val) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        </div>
      )
    },
    {
      title: "Название  склада",
      dataIndex: "name",
      key: "name",
      width: 60,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "ID склада продавца",
      dataIndex: "seller_warehouse_id",
      key: "seller_warehouse_id",
      width: 50,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Маркетплейс",
      dataIndex: "marketplace_id",
      key: "marketplace_id",
      width: 40,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img alt={"Маркетплейс"} style={{ borderRadius: "50%", width: "36px" }} src={marketPlacePictureMap[val as number]} />
        </div>
      )
    },
    {
      title: "Партнер",
      dataIndex: "partner_name",
      key: "partner_name",
      width: 70,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Статус склада",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      width: 25,
      render: (val) => {
        let color = errorTagColor

        switch (val) {
          case "Активный":
            color = successTagColor
            break
          case "Заблокирован":
            color = errorTagColor
            break
          case "Активируется":
            color = allTagColors[2]
            break
          case "На паузе":
            color = allTagColors[3]
            break
          case "В архиве":
            color = allTagColors[1]
            break

          default:
            color = errorTagColor
        }

        return <ColorTag color={color}>{val}</ColorTag>
      }
    },
    // {
    //   title: "Действия",
    //   dataIndex: "actions",
    //   key: "actions",
    //   width: 30,
    //   render: (value, record) => (
    //     <Space style={{ display: "flex", justifyContent: "center" }} direction="horizontal">
    //       <Button
    //         icon={<EditOutlined />}
    //         onClick={() => {
    //           setEditData({
    //             id: record.partner_id,
    //             name: record.name,
    //             api_key_standard: record.api_key_standard,
    //             api_key_stats: record.api_key_stats
    //           })
    //           setIsEditOpen(true)
    //         }}
    //       />
    //       <Button
    //         icon={<DeleteOutlined />}
    //         onClick={() => {
    //           Modal.confirm({
    //             title: "Вы уверены что хотите удалить склад?",
    //             icon: <ExclamationCircleOutlined />,
    //             okText: "Да",
    //             okType: "danger",
    //             cancelText: "Нет",
    //             onOk() {
    //               /*
    //               stockDelete(record.id, () => {
    //                 showSuccessMessage("Склад успешно удален.")
    //                 stockGetList()
    //               })
    //                */
    //             }
    //           })
    //         }}
    //         danger
    //       />
    //     </Space>
    //   )
    // }
  ]

  const categoryColumns: ColumnsType<any> = [
    {
      title: "#",
      dataIndex: "group_id",
      key: "group_id",
      width: 8,

      render: (val) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip placement="topLeft" title={val}>
            {val}
          </Tooltip>
        </div>
      )
    },
    {
      title: "Группа складов",
      dataIndex: "group_name",
      key: "group_name",
      width: 60,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Названия складов",
      dataIndex: "warehouse_names",
      key: "warehouse_names",
      width: 50,
      ellipsis: {
        showTitle: false
      },
      render: (val) => (
        <Tooltip placement="topLeft" title={val}>
          {val}
        </Tooltip>
      )
    },
    {
      title: "Действия",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (value, record) => (
        <Space style={{ display: "flex", justifyContent: "center" }} direction="horizontal">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setEditGroupData({
                id: record.group_id,
                name: record.group_name,
                selected_warehouses: record.selected_warehouses,
              })
              setIsEditGroupOpen(true)
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                title: "Вы уверены что хотите удалить группу складов?",
                icon: <ExclamationCircleOutlined />,
                okText: "Да",
                okType: "danger",
                cancelText: "Нет",
                onOk() {
                  stockDeleteGroup(record.group_id, () => {
                    showSuccessMessage("Группа складов успешно удалена.")
                    stockGetGroupItems()
                  })
                }
              })
            }}
            danger
          />
        </Space>
      )
    }
  ]

  const searchTable = (value: string) => {
    const searchString = value.toLowerCase()

    if (value) {
      const _data = tempData.filter((val: any) => {
        return (
          `${val.api_key_standard}`.toLowerCase().includes(searchString) ||
          `${val.name}`.toLowerCase().includes(searchString) ||
          `${val.api_key_stats}`.toLowerCase().includes(searchString) ||
          `${val.phone_binding_wb}`.toLowerCase().includes(searchString) ||
          `${val.created_at}`.toLowerCase().includes(searchString) ||
          `${val.updated_at}`.toLowerCase().includes(searchString)
        )
      })

      setTempData(_data)
    } else {
      setTempData(data)
    }
  }

  const tabs = [
    {
      key: "1",
      label: "Склады",
      children: (
        <div>
          <Collapse style={{ marginBottom: 20 }}>
            <Collapse.Panel header="Фильтр складов" key="1" >
              <FilterTable
                isLoading={isLoading}
                setFilters={setFilters}
                sorting={sorting}
              />
            </Collapse.Panel>
          </Collapse>
          <Table
            virtual={true}
            columns={partnersColumns}
            dataSource={tempData}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
            }}
            scroll={{ x: 1300 }}
            size="small"
            bordered
            pagination={{
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: [ "10", "25", "50", "100" ],
              onShowSizeChange: (_, size) => setPageSize(size),
            }}
          />
        </div>
      ),
    },
    {
      key: "2",
      label: "Категории складов",
      children: (
        <div>
          <Button
            style={{ marginBottom: "20px", background: "#00a2e8" }}
            type="primary"
            onClick={() => setIswarehouseOpen(true)}
            loading={isLoading}
          >
            <HomeOutlined style={{ marginLeft: "-5px" }} />
            Добавить группу складов
          </Button>
          <Table
            virtual={true}
            columns={categoryColumns}
            dataSource={groupItems.items}
            loading={{
              spinning: isLoading,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />
            }}
            scroll={{ x: 1300 }}
            size="small"
            bordered
            pagination={{
              pageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: [ "10", "25", "50", "100" ],
              onShowSizeChange: (_, size) => setPageSize(size),
            }}
          />
        </div>
      ),
    },
  ]

  return (
    <MainLayout pageTitle="Склады">
      <div className="page-wrapper">
        <PageHead
          title="Склады"
          extra={
            <div>
            </div>
          }
        />
        <div className="page-content">
          <Tabs
            activeKey={currentTab}
            items={tabs}
            type="card"
            onChange={(value) => setCurrentTab(value)}
            destroyInactiveTabPane
          />
          <WarehouseModal isOpen={isWarehouseOpen} setIsOpen={setIswarehouseOpen}/>
          {
            isEditGroupOpen && <WarehouseEditModal
              isOpen={isEditGroupOpen}
              setIsOpen={setIsEditGroupOpen}
              editData={editGroupData}
            />
          }
          <AddModal isOpen={isAddOpen} setIsOpen={setIsAddOpen} />
          {
            isEditOpen && <EditModal
              isOpen={isEditOpen}
              setIsOpen={setIsEditOpen}
              editData={editData}
            />
          }

        </div>
      </div>
    </MainLayout>
  )
}

export default Stock
