export const SERVER_BASE_URL =
  process.env.REACT_APP_API_URL || "https://api.sellercrm.pro"
export const API_BASE_URL = `${SERVER_BASE_URL}/api`
export const API_WS_URL = process.env.REACT_APP_WS_URL || "wss://sellercrm.pro:1404"

// auth routes
export const API_AUTH_LOGIN = `${API_BASE_URL}/auth/login`
export const API_AUTH_LOGOUT = `${API_BASE_URL}/auth/logout`
export const API_AUTH_REFRESH = `${API_BASE_URL}/auth/refresh-token`
export const API_AUTH_GET_USER = `${API_BASE_URL}/get-user-info`
export const API_AUTH_CONNECT_TELEGRAM = `${API_BASE_URL}/telegram/get-telegram-token`
export const API_AUTH_REMOVE_TELEGRAM = `${API_BASE_URL}/telegram/del/`
export const API_AUTH_SET_PRINT_SETTINGS = (id: number) => `${API_BASE_URL}/orders/print-settings/${id}`


// partners routes
export const API_PARTNERS_LIST = `${API_BASE_URL}/partners-info`
export const API_PARTNERS_ADD = `${API_BASE_URL}/partner-create`
export const API_PARTNERS_EDIT = `${API_BASE_URL}/partner-update`
export const API_PARTNERS_GET_BY_ID = `${API_BASE_URL}/partner-info`
export const API_PARTNERS_DELETE = `${API_BASE_URL}/partner-delete`
export const API_PARTNERS_VALID_API = `${API_BASE_URL}/validate-api-key`
// stock routes
export const API_STOCK_LIST = `${API_BASE_URL}/warehouses/items`
export const API_INVENTORIZATION_LIST = `${API_BASE_URL}/inventories/item`
export const API_INVENTORIZATION_UPLOAD = `${API_BASE_URL}/inventories/upload-modified-stock`
export const API_STOCK_FILTER_LIST = `${API_BASE_URL}/warehouses/filter-list`
export const API_STOCK_GROUP_ITEMS = `${API_BASE_URL}/warehouses/group-items`
export const API_STOCK_ADD_GROUP_ITEMS = `${API_BASE_URL}/warehouses/add-group`
export const API_STOCK_DELETE_GROUP_ITEMS = `${API_BASE_URL}/warehouses/del`
export const API_STOCK_GET_GROUP_ITEM = `${API_BASE_URL}/warehouses/group-info`
export const API_STOCK_UPDATE_GROUP_ITEM = `${API_BASE_URL}/warehouses/upd-group`

// staff routes
export const API_STAFF_LIST = `${API_BASE_URL}/users-info`
export const API_STAFF_ONE = `${API_BASE_URL}/user-info`
export const API_STAFF_ADD = `${API_BASE_URL}/create-user`
export const API_STAFF_EDIT = `${API_BASE_URL}/update-user`
export const API_STAFF_DELETE = `${API_BASE_URL}/delete-user`
export const API_META_GET_MANAGERS = `${API_BASE_URL}/list-of-manager`
export const API_META_GET_PARTNERS = `${API_BASE_URL}/list-of-partners`
export const API_META_GET_ROLES = `${API_BASE_URL}/list-of-roles`
export const API_META_GET_WP_STATUSES = `${API_BASE_URL}/list-of-status-whatsapp`
//stock-fbs routes
export const API_STOCK_FBS_GET_PARTNERS = `${API_BASE_URL}/orders/list-of-partners`
export const API_STOCK_FBS_GET_CHECK_AND_CLOSE_SUPPLIER = `${API_BASE_URL}/suppliers/check-and-close-supply`
export const API_STOCK_FBS_SADOVOD_TO_BUILD = `${API_BASE_URL}/orders/gardener-data-migrator`
export const API_STOCK_FBS_ADD_IMPORT_TASK = `${API_BASE_URL}/orders/add`
export const API_STOCK_FBS_GET_IMPORT_RESULT = `${API_BASE_URL}/orders/import-task-result`
export const API_STOCK_FBS_GET_SUPPLIERS_IMPORT_RESULT = `${API_BASE_URL}/supplies/supplies`
export const API_STOCK_FBS_GET_TABLE_DATA = `${API_BASE_URL}/orders/items`
export const API_STOCK_FBS_STICKER_REMOVE = `${API_BASE_URL}/orders/remove-sticker`
export const API_STOCK_FBS_STICKER_DOWNLOAD = `${API_BASE_URL}/orders/download-export-pdf`
export const API_STOCK_FBS_LABEL_DOWNLOAD = `${API_BASE_URL}/orders/download-export-label-pdf`
export const API_STOCK_FBS_STICKER_UPDATE = `${API_BASE_URL}/orders/load-sticker`
export const API_STOCK_FBS_SUPPLY_ADD = `${API_BASE_URL}/orders/create_supply`
export const API_STOCK_FBS_SUPPLY_UPDATE = `${API_BASE_URL}/orders/link-order-to-delivery`
export const API_STOCK_FBS_DOWNLOAD_EXCEL = `${API_BASE_URL}/orders/download-orders-in-excel`
export const API_INVENTORIZATION_DOWNLOAD = `${API_BASE_URL}/inventories/download-current-stock`
export const API_STOCK_FBS_UPDATE_STATUS = `${API_BASE_URL}/orders/update-order-status`
export const API_STOCK_FBS_PRINT_LABEL = `${API_BASE_URL}/orders/label-printing`
export const API_STOCK_FBS_PRINT_STICKER = `${API_BASE_URL}/orders/sticker-printing`
export const API_STOCK_FBS_UPDATE_ORDERS = `${API_BASE_URL}/orders/update-orders`
export const API_STOCK_FBS_TRANSFER_TO_DELIVERY = `${API_BASE_URL}/orders/transfer_orders_to_delivery`
export const API_STOCK_FBS_FILTER_LIST = `${API_BASE_URL}/orders/filter-list`;
export const API_STOCK_FBS_MIGRATE_OLD = `${API_BASE_URL}/orders/migrate-old`;


//stock-suppliers routes
export const API_STOCK_SUPPLIERS_GET_PARTNERS = `${API_BASE_URL}/orders/list-of-partners`
export const API_STOCK_SUPPLIERS_GET_SUPPLIER = `${API_BASE_URL}/suppliers/item`
export const API_STOCK_SUPPLIERS_DELETE_SUPPLIER = `${API_BASE_URL}/supplies/delete-supply`
export const API_STOCK_SUPPLIERS_ADD_IMPORT_TASK = `${API_BASE_URL}/supplies/update-supplies`
export const API_STOCK_SUPPLIERS_GET_IMPORT_RESULT = `${API_BASE_URL}/orders/import-task-result`
export const API_STOCK_SUPPLIERS_GET_TABLE_DATA = `${API_BASE_URL}/supplies/items`
export const API_STOCK_SUPPLIERS_STICKER_REMOVE = `${API_BASE_URL}/orders/remove-sticker`
export const API_STOCK_SUPPLIERS_STICKER_DOWNLOAD = `${API_BASE_URL}/orders/download-export-pdf`
export const API_STOCK_SUPPLIERS_LABEL_DOWNLOAD = `${API_BASE_URL}/supplies/download-export-label-pdf`
export const API_STOCK_SUPPLIERS_STICKER_UPDATE = `${API_BASE_URL}/orders/load-sticker`
export const API_STOCK_SUPPLIERS_SUPPLY_ADD = `${API_BASE_URL}/orders/create_supply`
export const API_STOCK_SUPPLIERS_SUPPLY_UPDATE = `${API_BASE_URL}/orders/link-order-to-delivery`
export const API_STOCK_SUPPLIERS_DOWNLOAD_EXCEL = `${API_BASE_URL}/orders/download-orders-in-excel`
export const API_STOCK_SUPPLIER_DOWNLOAD_EXCEL = `${API_BASE_URL}/supplies/download-supplies-in-excel`
export const API_STOCK_SUPPLIERS_UPDATE_STATUS = `${API_BASE_URL}/orders/update-order-status`
export const API_STOCK_SUPPLIERS_SYNC_SUPPLY = `${API_BASE_URL}/supplies/synchronize-supply`
export const API_STOCK_SUPPLIERS_PRINT_LABEL = `${API_BASE_URL}/orders/label-printing`
export const API_STOCK_SUPPLIERS_PRINT_STICKER = `${API_BASE_URL}/orders/sticker-printing`
export const API_STOCK_SUPPLIERS_UPDATE_ORDERS = `${API_BASE_URL}/orders/update-orders`
export const API_STOCK_SUPPLIERS_TRANSFER_TO_DELIVERY = `${API_BASE_URL}/orders/transfer_orders_to_delivery`

//dropshipping route
export const API_DROPSHIPPING_POST_TABLE_DATA = `${API_BASE_URL}/dropshipping/items`
export const API_DROPSHIPPING_ADD_SUPLIERS = `${API_BASE_URL}/dropshipping/suppliers-add`
export const API_DROPSHIPPING_EXPORT_EXCEL = `${API_BASE_URL}/dropshipping/orders-in-excel`
export const API_DROPSHIPPING_SUPPLIERS = `${API_BASE_URL}/dropshipping/suppliers`
export const API_DROPSHIPPING_SYNC = `${API_BASE_URL}/dropshipping/sync-dropshipping-data`

//dropshipping-suppliers route
export const API_DROPSHIPPING_SUPPLIERS_POST_TABLE = `${API_BASE_URL}/products/supplier-items`
export const API_DROPSHIPPING_SUPPLIERS_DELETE = `${API_BASE_URL}/products/remove-product-from-supplier/`
export const API_DROPSHIPPING_ADD_PRODUCT_PUCHASED = `${API_BASE_URL}/dropshipping/add-product-purchased`

//purchase table routes
export const API_PURCHASE_TABLE_GET_TABLE = `${API_BASE_URL}/products/purchases-items`
export const API_PURCHASE_GET_FILTER = `${API_BASE_URL}/products/filter-list`
export const API_PURCHASE_DELETE = `${API_BASE_URL}/products/remove`
export const API_PURCHASE_EXPORT_EXCEL = `${API_BASE_URL}/dropshipping/purchases-in-excel`

//sbermarket routes
export const API_SBERMARKET_GET_COMPANIES = `${API_BASE_URL}/sbermarket/companies`
export const API_SBERMARKET_ADD_IMPORT_TASK = `${API_BASE_URL}/sbermarket/tasks-import-order`
export const API_SBERMARKET_GET_IMPORT_RESULT = `${API_BASE_URL}/sbermarket/import-task-result`
export const API_SBERMARKET_GET_LIST = `${API_BASE_URL}/sbermarket/items`
export const API_SBERMARKET_COOKIES = `${API_BASE_URL}/sbermarket/check-cookies`
export const API_SBERMARKET_GET_FILTERS = `${API_BASE_URL}/sbermarket/filter-list`

export const API_COLLECT_ORDERS = `${API_BASE_URL}/orders/mark-collected`
export const API_COLLECT_SCAN_CIS = `${API_BASE_URL}/orders/scan-cis`
export const API_ADD_CIS = `${API_BASE_URL}/orders/add-cis`

// items routes
export const API_ITEMS_GET_LIST = `${API_BASE_URL}/items`
export const API_ITEMS_GET_LISTS = `${API_BASE_URL}/filter-list`
export const API_ITEMS_GET_SUPPLIERS_LIST = `${API_BASE_URL}/cards/list-of-supplier`
export const API_ITEMS_GET_ITEM = `${API_BASE_URL}/cards/info-card-product`
export const API_ITEMS_CREATE = `${API_BASE_URL}/cards/add-card-product`
export const API_ITEMS_UPDATE = `${API_BASE_URL}/cards/update-card-product`
export const API_ITEMS_DELETE = `${API_BASE_URL}/cards/del-card-product`
export const API_ITEMS_UPDATE_PRODUCTS = `${API_BASE_URL}/cards/update-card-products`
export const API_ITEMS_UPDATE_CARD = `${API_BASE_URL}/cards/update-card-status`
export const API_ITEMS_ASSIGN_SUPPLIERS = `${API_BASE_URL}/cards/assign-suppliers`
export const API_ITEMS_ASSIGN_PRICING = `${API_BASE_URL}/cards/assign-pricing`

// prices routes
export const API_PRICES_GET_LIST = `${API_BASE_URL}/prices/items`
export const API_PRICES_GET_LISTS = `${API_BASE_URL}/prices/marketplace-list`
export const API_PRICES_SEND = `${API_BASE_URL}/prices/send`
// settings
export const API_MONITORING_FETCH = `${API_BASE_URL}/monitoring/items`
export const API_MONITORING_PRICE_FETCH = `${API_BASE_URL}/monitoring/export-check-price`
export const API_MONITORING_ORDER_FETCH = `${API_BASE_URL}/monitoring/export-check-count-orders`
export const API_MONITORING_STATUS = `${API_BASE_URL}/monitoring/status`
export const API_MONITORING_UPDATE = `${API_BASE_URL}/monitoring/update`
export const API_MONITORING_DELETE_FILE = `${API_BASE_URL}/monitoring/delete-export`
export const API_MONITORING_DOWNLOAD_FILE = `${API_BASE_URL}/monitoring/download-export`
export const API_MONITORING_GET_IMPORTS = `${API_BASE_URL}/export-file`
export const API_MONITORING_IMPORT = `${API_BASE_URL}/import-file`
// import products
export const API_IMPORT_PRODUCTS = `${API_BASE_URL}/import/items/`
export const API_IMPORT_LIST_OF_PARTNERS = `${API_BASE_URL}/import/list-of-partners/`
export const API_IMPORT_ADD_TASK = `${API_BASE_URL}/import/add`
export const API_IMPORT_UPDATE_STATUS = `${API_BASE_URL}/import/upd-status`
export const API_IMPORT_GET_STATUSES = `${API_BASE_URL}/import/import-statuses`
export const API_IMPORT_REMOVE_TASK = `${API_BASE_URL}/import/del/`
// telegram notifications
export const API_TELEGRAM_GET_ITEMS = `${API_BASE_URL}/telegram/items`
export const API_TELEGRAM_UPDATE_STATUS = `${API_BASE_URL}/telegram/status`
export const API_TELEGRAM_REMOVE_USER = `${API_BASE_URL}/telegram/del/`
//export
export const API_EXPORT_GET_DATA = `${API_BASE_URL}/operation/items-export`
export const API_EXPORT_REMOVE_FILE = `${API_BASE_URL}/operation/delete-export/`
export const API_EXPORT_DOWNLOAD_FILE = `${API_BASE_URL}/operation/download-export/`
export const API_EXPORT_ADD_TASK = `${API_BASE_URL}/operation/set-export`
// suppliers
export const API_SUPPLIERS_GET_DATA = `${API_BASE_URL}/suppliers/items`
export const API_SUPPLIERS_ADD = `${API_BASE_URL}/suppliers/add`
export const API_SUPPLIERS_UPDATE = `${API_BASE_URL}/suppliers/upd`
export const API_SUPPLIERS_REMOVE = `${API_BASE_URL}/suppliers/del/`
export const API_SUPPLIERS_FILTER = `${API_BASE_URL}/suppliers/filter-phone`
export const API_SUPPLIER_CLOSE = `${API_BASE_URL}/supplies/close-supply`
// analytics routes
export const API_ANALYTICS_FILTER_LIST = `${API_BASE_URL}/analytics/filter-list`;
export const API_ANALYTICS_ORDERS_BY_PERIOD = `${API_BASE_URL}/analytics/orders-by-period`;
export const API_ANALYTICS_DOWNLOAD_EXCEL = `${API_BASE_URL}/analytics/download-in-excel`;
// update articles routes
export const API_UPDATE_ARTICLES = `${API_BASE_URL}/articles/item`
export const API_UPDATE_ARTICLES_UPLOAD = `${API_BASE_URL}/articles/upload-modified-articles`

