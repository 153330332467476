import React, { useState } from "react"

import { Form, Input, Modal } from "antd"

import { stockFBSMarketApi } from "../../../common/api/endpoints/stockFBSMarketApi"
import { showErrorMessage, showSuccessMessage } from "../../../utils/ui"

type Props = {
  state: {visible: boolean, id: number | null, cis: string[] | null};
    setState: React.Dispatch<
        React.SetStateAction<{visible: boolean, id: number | null, cis: string[] | null}>
    >;
    prevState: {id: number | null, cis: string[] | null}
    setPrevState: React.Dispatch<
    React.SetStateAction<{id: number | null, cis: string[] | null}>>;
};

const ChangeCisModal = ({ setState, state, prevState, setPrevState }: Props) => {
  const initState = () => {
    console.log(prevState,456789)
    if(state.id === prevState.id){
      return prevState.cis
    }else{
      return state.cis
    }
  }
  const [ cis, setCis ] = useState<string[]>([ ...(initState() ?? []),"" ])
  const [ form ] = Form.useForm()
  const handleModalOk = () => {
    form.validateFields().then((values: object) => {
      const newCis: string[] = []
      Object.values(values).map((value) => {
        if(value){
          newCis.push(value)
        }
        return value
      })
      if(state.id){
        try{
          stockFBSMarketApi.fetchAddCis({ id: state.id, cis: newCis as string[] })
            .then((res)=>{
              if(res.status === 200){
                setPrevState({ id: state.id, cis: res.data?.cis })
                setState({
                  visible: false,
                  id: null,
                  cis: null,
                })
                setCis([ ...res.data?.cis as string[], "" ])
                showSuccessMessage("Кизы добавлены")
              }
              form.resetFields()
            })
            .catch((err)=>{
              showErrorMessage(err.response?.data?.message ?? "Не удалось добавить Кизы")
            })
        }catch(er){
          setState({
            visible: false,
            id: null,
            cis: null,
          })
          form.resetFields()
          showErrorMessage("Произошла ошибка")
        }
      }
    })
  }

  const handleModalCancel = () => {
    setState({
      visible: false,
      id: null,
      cis: null,
    })
    form.resetFields()
  }

  return (
    <>
      <Modal
        title="Добавить КИЗЫ"
        open={state.visible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form 
          form={form} 
          layout="vertical" >
          {cis?.map((cisCode,index)=>(
            <Form.Item
              name={index}
              initialValue={cisCode}
            >
              {++index === cis.length?
                <Input onChange={()=>setCis([ ...cis, "" ])}/>
                :<Input onChange={(e) =>{
                  if(e.target.value){
                    return null
                  }else{
                    const values = cis.filter((item,indexItem) => index!==indexItem)
                    setCis(values)
                  }
                }
                }/>}
            </Form.Item>))}

        </Form>
      </Modal>
    </>
  )
}

export default ChangeCisModal
