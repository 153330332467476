import { itemSliceActions } from "./itemSlice"
import { itemsApi } from "../../../common/api/endpoints/itemsApi"
import { handleResponseError } from "../../../common/api/middleware"
import { showErrorNotification, showSuccessMessage } from "../../../utils/ui"
import { TAppDispatch } from "../../store"
import { stockApi } from "../../../common/api/endpoints/stockApi"

export const itemActions = {
  ...itemSliceActions,
  setItemCardsUpdate: (payload: boolean) => (dispatch: TAppDispatch) => {
    dispatch(itemActions.setItemLoading(payload))
  },
  itemsGetListsData: () => (dispatch: TAppDispatch) => {
    dispatch(itemActions.setItemListsLoading(true))

    itemsApi
      .fetchListsData()
      .then((response) => {
        if (response.status === 200) {
          dispatch(itemActions.setItemLists({ ...response.data }))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(itemActions.setItemListsLoading(false))
      })
  },

  itemsGetAssignSuppliers: (onFinish?: ()=>void) => (dispatch: TAppDispatch) => {
    dispatch(itemActions.setSuppliersLoading(true))

    itemsApi
      .fetchAssignSuppliers()
      .then((response) => {
        if (response.status === 200) {
          showSuccessMessage(response.data);
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(itemActions.setSuppliersLoading(false))
        onFinish && onFinish()
      })
  },

  itemsGetAssignPricing: (onFinish?: ()=>void) => (dispatch: TAppDispatch) => {
    dispatch(itemActions.setPricingLoading(true))

    itemsApi
      .fetchPricing()
      .then((response) => {
        if (response.status === 200) {
          showSuccessMessage(response.data);
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(itemActions.setPricingLoading(false))
        onFinish && onFinish()
      })
  },

  itemsGetList: (filters: any) => (dispatch: TAppDispatch) => {
    dispatch(itemActions.setItemLoading(true))

    itemsApi
      .fetchList(filters)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data

          dispatch(itemActions.setItemData(data.rows))
          dispatch(
            itemActions.setItemPagesData({
              total: data.total || 0,
              totalPages: data.totalPages || 0,
              start: data.start || 1,
              end: data.end || 0,
              currentPage: data.currentPage || 1,
              pageSize: data.pageSize || 10
            })
          )
          dispatch(itemActions.setItemBannerData(data.banner))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        dispatch(itemActions.setItemLoading(false))
      })
  },

  itemCreate: (
    data: any,
    onFinish: () => void,
    onFormError: (errors: { [key: string]: string }) => void
  ) =>
    (dispatch: TAppDispatch) => {
      dispatch(itemActions.setItemLoading(true))

      itemsApi
        .create(data)
        .then((response) => {
          if (response.status === 200) {
            onFinish && onFinish()
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            if (err.response?.data?.code === 23000) {
              showErrorNotification("Такой товар уже существует, проверьте данные и повторите попытку.")
              return
            } else {
              onFormError(err.response.data)
            }
          }

          handleResponseError(dispatch, err)
        })
        .finally(() => {
          dispatch(itemActions.setItemLoading(false))
        })
    },

  itemUpdate:
    (
      data: any,
      onFinish: () => void,
      onFormError: (errors: { [key: string]: string }) => void
    ) =>
      (dispatch: TAppDispatch) => {
        dispatch(itemActions.setItemLoading(true))

        itemsApi
          .update(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              onFormError(err.response.data)
            }

            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(itemActions.setItemLoading(false))
          })
      },

  itemDelete:
    (id: string | number, onFinish: () => void) => (dispatch: TAppDispatch) => {
      dispatch(itemActions.setItemLoading(true))

      itemsApi
        .delete(id)
        .then((response) => {
          if (response.status === 200) {
            onFinish && onFinish()
          }
        })
        .catch((err) => {
          handleResponseError(dispatch, err)
        })
        .finally(() => {
          dispatch(itemActions.setItemLoading(false))
        })
    },

  itemGetSuppliersList:
    (search: string, setSuppliers: (v: any) => void, onFinish?: any) =>
      (dispatch: TAppDispatch) => {
        itemsApi
          .fetchSuppliers(search)
          .then((response) => {
            setSuppliers(response.data || [])
          })
          .catch((err) => {
            console.log(err.response)
            if (err.response?.status === 404) {
              setSuppliers([])
            } else {
              handleResponseError(dispatch, err)
            }
          })
          .then(() => {
            onFinish && onFinish()
          })
      },

  itemUpdateProduct:
    (data: any, onFinish?: any) =>
      (dispatch: TAppDispatch) => {
        dispatch(itemActions.setItemLoading(true))

        itemsApi
          .fetchUpdateProducts(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            console.log(err.response)
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(itemActions.setItemLoading(false))
          })
      },

  itemUpdateCards:
    (data: any, onFinish?: any) =>
      (dispatch: TAppDispatch) => {
        dispatch(itemActions.setItemLoading(true))

        itemsApi
          .fetchUpdateCards(data)
          .then((response) => {
            if (response.status === 200) {
              onFinish && onFinish()
            }
          })
          .catch((err) => {
            console.log(err.response)
            handleResponseError(dispatch, err)
          })
          .finally(() => {
            dispatch(itemActions.setItemLoading(false))
          })
      },
  articlesGetList: (isSocket = false, onFinish?: () => void) => (dispatch: TAppDispatch) => {
    !isSocket && dispatch(itemActions.setItemLoading(true))

    stockApi
      .fetchUpdateArticles()
      .then((response) => {
        if (response.status === 200) {
          dispatch(itemActions.setArticlesData(response.data))
        }
      })
      .catch((err) => {
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        !isSocket && dispatch(itemActions.setItemLoading(false))
        onFinish && onFinish()
      })
  },
}
