import React, { FC, useEffect, useMemo, useRef, useState } from "react"

import {
  DeleteOutlined,
  LoadingOutlined,
  MenuOutlined,
  SaveOutlined,
} from "@ant-design/icons"
import { Button, Table, Tooltip, Form, Input, Image, Space, Popconfirm, InputNumber, Spin, Alert, Upload } from "antd"
import { ColumnsType } from "antd/es/table"
import { useParams } from "react-router-dom"


import { monitoringApi } from "../../common/api/endpoints/monitoringApi"
import { stockApi } from "../../common/api/endpoints/stockApi"
import { handleResponseError } from "../../common/api/middleware"
import { API_WS_URL } from "../../common/api/urls"
import MainLayout from "../../common/components/layouts/MainLayout"
import PageHead from "../../common/components/page-header/PageHeader"
import { useActions } from "../../common/hooks/useActions"
import { useTypedSelector } from "../../common/hooks/useTypedSelector"
import { TAppDispatch } from "../../redux/store"
import { getAccessToken } from "../../utils/auth"
import {showErrorMessage, showSuccessMessage} from "../../utils/ui"

const Inventarization: FC = () => {

  const { isLoading, inventoriesData,isUpdate } = useTypedSelector((state) => state.stock)
  const [ isDisabledDownloading,setDisabledDownloadButton ] = useState(false)
  const {
    inventarizationGetList,
    setStockUpdate
  } =
        useActions()
  const [ isSocket, setIsSocket ] = useState(false)
  const [ isLoadingImport,setIsLoadingImport ] = useState(false)
  const connectWebSocket = (attempt = 1) => {
    const socket = new WebSocket(`${API_WS_URL}/?token=${getAccessToken()}`)
    const maxAttempts = 3

    socket.onmessage = (e) => {
      try {
        const updates = JSON.parse(e.data)
        if (updates) {
          if (updates?.["update-inventories"]?.status === true) {
            setStockUpdate(true)
            setIsSocket(true)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }

    socket.onerror = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }

    socket.onclose = () => {
      if (attempt < maxAttempts) {
        showErrorMessage(`Попытка ${attempt} для переподключения к сокетам...`)
        setTimeout(() => connectWebSocket(attempt + 1), 1500)
      } else {
        showErrorMessage("К сожалению подключение к сокетам не удалось, пожалуйста проверьте ваше интернет соединение и перезагрузите страницу.")
      }
    }
  }

  useEffect(()=>{
    connectWebSocket()
  },[])


  useEffect(()=>{
    if(isUpdate){
      inventarizationGetList(isSocket)
      setStockUpdate(false)
      setIsSocket(false)
    }
  },[ isUpdate,isSocket ])

  useEffect(() => {
    inventarizationGetList(isSocket)
  }, [ ])

  const handleDownloadLatestProducts = async () => {
    try{
      setDisabledDownloadButton(true)
      await stockApi.downloadLastProducts()
    }catch(err){
      console.log(err)
    }finally{
      setDisabledDownloadButton(false)
    }
  }
  const importFile = (file: File | Blob | string) => {
    if (!file) return

    setIsLoadingImport(true)

    stockApi.importFile(file)
      .then(() => {
        showSuccessMessage("Файл успешно импортирован.");
      })
      .catch((err) => {
        const dispatch = (() => { }) as TAppDispatch
        setIsLoadingImport(false)
        handleResponseError(dispatch, err)
      })
      .finally(() => {
        setIsLoadingImport(false)
      })
  }


  return (
    <>
      <MainLayout pageTitle={"Инвентаризация"}>
        <div className="page-wrapper">
          <PageHead
            titleStyles={{ fontWeight: "600" }}
            title={inventoriesData?.created_at || ""}
            extra={<div></div>}
          />
          {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>}/>}

          {inventoriesData?.msg?.msg &&
              <Alert
                style={{
                  marginLeft: "24px",
                  marginRight: "24px",
                  backgroundColor: `#${inventoriesData?.msg?.color}`
                }}
                type={inventoriesData?.msg?.error_status ? "error" : "success"} message={<span dangerouslySetInnerHTML={{ __html: inventoriesData?.msg?.msg }}></span>}
                showIcon/>
          }
          <div style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            marginLeft: "24px",
            marginTop: "16px"
          }}>
            <Upload
              disabled={isLoadingImport}
              maxCount={1}
              fileList={[]}
              customRequest={(options) => importFile(options.file)}
            >
              <Button
                type="primary"
                disabled={isLoadingImport}
                loading={isLoadingImport}
                style={{
                  background: "#00a2e8",
                  borderColor: "#00a2e8",
                }}
                className={"blueButton"}
              >
                <span>
                ЗАГРУЗИТЬ ИЗМЕНЕННЫЕ ОСТАТКИ ТОВАРОВ
                </span>
              </Button>
            </Upload>
          </div>
          <div style={{
            display: "grid",
            gridTemplateColumns: "340px 250px",
            gap: "16px",
            marginTop: "16px",
            marginLeft: "24px"
          }}>
            <span style={{ fontWeight: 600, fontSize: 16 }}>ВСЕГО ЯЧЕЕК</span>
            <span style={{
              width: "100%",
              fontWeight: 500,
              textDecoration: "underline"
            }}>{inventoriesData?.total_cells}</span>

            <span style={{ fontWeight: 600, fontSize: 16 }}>СОВПАДАЮТ С ТЕКУЩИМИ</span>
            <span style={{
              width: "100%",
              fontWeight: 500,
              textDecoration: "underline",
            }}>{inventoriesData?.current_match}</span>

            <span style={{ fontWeight: 600, fontSize: 16 }}>ПРОВЕДЕНА ИНВЕНТАРИЗАЦИЯ ТОВАРОВ</span>
            <span style={{
              width: "100%",
              fontWeight: 500,
              color: "blue",
              textDecoration: "underline"
            }}>{inventoriesData?.inventory_quantity}</span>
          </div>
        </div>
      </MainLayout>
    </>
  )
}


export default Inventarization
